<!--
 * @Description  : 
 * @Author       : BigBigger
 * @Date         : 2021-11-12 09:43:05
 * @LastEditTime : 2023-03-29 16:19:51
 * @LastEditors  : BigBigger
-->
<!--审核状态页面-->
<template>
  <div class="review-box">
    <!-- 关联订单 -->
    <template v-if="orderDetail && orderDetail.type ===1">
      <template v-if="orderDetail">
        <!-- 待审核 -->
        <div class="reviewStatus"  v-if="orderDetail.payInfo.confirmStatus === 1">
          <img src="@/assets/images/h5-icon/wating.png">
          <span>待审核</span>
          <p>{{tipList.submitAuditPrompt}}</p>
          <a @click="toOrderDetail">查看课程详情</a>
        </div>
        <!-- 等待中 -->
        <div class="reviewStatus"  v-if="orderDetail.payInfo.confirmStatus === 3">
          <img src="@/assets/images/h5-icon/wating.png">
          <span>审核中</span>
          <p>{{tipList.waitPrompt}}</p>
          <a @click="toOrderDetail">查看课程详情</a>
        </div>
        <!-- 审核失败 -->
        <div class="reviewStatus"  v-if="orderDetail.payInfo.confirmStatus === 4">
          <img src="@/assets/images/h5-icon/nopass.png">
          <span>审核未通过，岗位已满</span>
          <p>{{tipList.refusePrompt}}</p>
          <a @click="toOrderDetail">查看课程详情</a>
        </div>
        <!-- 审核通过 -->
        <template v-if="orderDetail.payInfo.confirmStatus === 2">
           <!-- 报名成功 -->
           <div class="reviewStatus" v-if="orderDetail.payInfo.orderStatus === 2">
              <img src="@/assets/images/h5-icon/pass.png">
              <span>报名成功</span>
              <p>{{tipList.paySuccessPrompt}}</p>
              <a @click="toOrderDetail">查看课程详情</a>
            </div>
           <!-- 其余状态 -->
           <div class="reviewStatus" v-if="orderDetail.payInfo.orderStatus !== 2">
              <img src="@/assets/images/h5-icon/pass.png">
              <span>审核已通过</span>
              <p>{{tipList.successPrompt}}</p>
              <a @click="toOrderDetail">查看课程详情</a>
            </div>
        </template>
        <template v-if="orderDetail && orderDetail.payInfo.confirmStatus === 2 && orderDetail.payInfo.payStatus === 1 && orderDetail.payInfo.orderStatus === 1">
          <div class="payWay">
            <div class="title"><span>支付形式</span></div>
             <van-radio-group v-model="payWay">
                <div class="infoList" v-if="isZJ">
                  <img src="@/assets/images/h5-icon/weixin.png">
                  <span class="way">微信付款</span>
                  <van-radio name="1" checked-color="#FF3F47"></van-radio>
                </div>
                <div class="infoList">
                  <img src="@/assets/images/h5-icon/zfb.png">
                  <span class="way">支付宝付款</span>
                  <van-radio name="2" checked-color="#FF3F47"></van-radio>
                </div>
              </van-radio-group>
              <PayType ref="paytype" :discountInfo="orderDetail.personalInfo" :courseInfo="orderDetail.courseInfo" v-model="orderDetail.payWay" v-if="orderDetail" />
            <div class="money">
              <span>实付金额：</span>
              <span class="red">￥{{+orderDetail.payWay === 1 ? orderDetail.courseInfo.payMoney : orderDetail.courseInfo.deposit}}</span>
            </div>
          </div>
          <div class="paybox">
            <p>支付方式：{{orderDetail.payWay | paywayFilter}}</p>
            <p class="payBtn" @click="toPay">立即支付</p>
          </div>
        </template>
      </template>
    </template>
    <!-- 非关联订单 -->
    <template v-if="orderDetail && orderDetail.type !==1">
      <div class="reviewStatus" v-if="orderDetail.payInfo.orderStatus === 2">
        <img src="@/assets/images/h5-icon/pass.png">
        <span>支付成功</span>
        <p>{{tipList.paySuccessPrompt}}</p>
        <a @click="toOrderDetail">查看课程详情</a>
      </div>
      <template v-if="orderDetail.payInfo.orderStatus !== 2">
        <div class="payWay">
          <div class="title"><span>支付形式</span></div>
          <van-radio-group v-model="payWay">
            <div class="infoList" v-if="isZJ">
              <img src="@/assets/images/h5-icon/weixin.png">
              <span class="way">微信付款</span>
              <van-radio name="1" checked-color="#FF3F47"></van-radio>
            </div>
            <div class="infoList">
              <img src="@/assets/images/h5-icon/zfb.png">
              <span class="way">支付宝付款</span>
              <van-radio name="2" checked-color="#FF3F47"></van-radio>
            </div>
          </van-radio-group>
          <PayType ref="paytype" :discountInfo="orderDetail.personalInfo" :courseInfo="orderDetail.courseInfo" v-model="orderDetail.payWay" v-if="orderDetail" />
          <div class="money">
            <span>实付金额：</span>
            <span class="red">￥{{+orderDetail.payWay === 1 ? getPrice : orderDetail.courseInfo.deposit}}</span>
          </div>
        </div>
        <div class="paybox">
          <p>支付方式：{{orderDetail.payWay | paywayFilter}}</p>
          <p class="payBtn" @click="toPay">立即支付</p>
        </div>
      </template>
    </template>

    <van-dialog
      v-model="showCB"
      title="请确认微信支付是否已完成"
      showCancelButton
      confirmButtonText="已完成支付"
      cancelButtonText="支付未完成"
      @confirm="confirmWexinPay()"
      @cancel="cancelWexinPay()"
    >
    </van-dialog>
  </div>

</template>
<script>
import { PAYWAY } from '@/config/config'
import { getOrderDetail, getLessonGroupTips, pay, payIsComplete } from '@/api/api'
import qs from 'qs'
import { Dialog } from 'vant';
import PayType from '@/components/courseGroup/signup/PayType.vue';
export default {
  name:'review',
  data() {
    return {
      orderId: null,
      orderDetail: null,
      tipList: {},
      payWay: "2",
      isZJ: false,
      code: this.$route.query.code || null,
      showCB: this.$route.query.showCB || false,
      webType:null,
      requestUrl: process.env.NODE_ENV === 'production' ? 'https://webh5.jsbxgwy.com/' : 'http://webh5dev.jsbxgwy.com/' 
    }
  },
  components: { PayType },
  mounted(){
    this.isZJ = process.env.VUE_APP_AREA === 'zj' ? true : false 
    this.orderId = this.$route.query.orderId
    this.getOrderDetail()
  },
  filters: {
    paywayFilter(val) {
      return PAYWAY[+val] || ''
    }
  },
  computed: {
    getPrice() {
      if (!this.orderDetail.courseInfo || !this.orderDetail.personalInfo) return '';
      if (
        this.orderDetail.personalInfo.studentsPreferential === 1
        && this.orderDetail.personalInfo
      ) {
        return this.orderDetail.personalInfo.afterOldStudentsPrice;
      }
      return this.orderDetail.courseInfo.payMoney;
    },
  },
  beforeDestroy() {
    // if (this.showCB) {
    //   this.confirmWexinPay()
    // }
  },
  methods:{
    getIsWxClient () {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      }
      return false;
    },
    toOrderDetail() {
      this.$router.push({path: `/course/${this.orderDetail.courseGroupId}`})
    },
    async getOrderDetail() {
      let params = {
        orderId: this.orderId
      }
      let response = await getOrderDetail(params)
      if(+response.returnCode !== 10001) {
        this.$toast(response.returnMsg)
        return false
      }
      this.orderDetail = response.data
      this.getLessonGroupTips(this.orderDetail.courseGroupId)
      if(this.code){
        this.payWay = this.$store.state.payWay || null
        this.orderDetail = this.$store.state.orderDetail || null
        this.webType = 1
        this.toPay()
      }
    },

    async getLessonGroupTips(courseGroupId) {
      let response = await getLessonGroupTips({courseGroupId: courseGroupId})
      this.tipList = response.data
    },

    getCode() {
      const currentUrl = window.location.href
      const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx4f53f5a92d9ec864&redirect_uri=${this.urlencode(
        currentUrl
      )}&response_type=code&scope=snsapi_base&state=${this.orderId}#wechat_redirect`

      window.location.href = url

    },

    urlencode(str) {
      str = (str + '').toString()
      return encodeURIComponent(str)
        .replace(/!/g, '%21')
        .replace(/'/g, '%27')
        .replace(/\(/g, '%28')
        .replace(/\)/g, '%29')
        .replace(/\*/g, '%2A')
        .replace(/%20/g, '+')
    },

    async toPay(){
      // 判断是否微信环境
      let isWX = this.getIsWxClient()
      
      if (!this.code) {
        if(isWX && this.payWay == 1){
          this.webType = 1
          this.$store.dispatch('setOrderDetail', this.orderDetail)
          this.$store.dispatch('setPayWay', this.payWay)
          let orderDetail = this.$store.state.orderDetail || null
          this.code = this.getCode() 
          return
          
        }else{  //外部浏览器微信支付
            this.webType = 0
        }
        
      }

      let params = {
        courseGroupId: this.orderDetail.courseGroupId,
        type: +this.payWay,
        from: 2,
        orderCode: this.orderDetail.orderCode,
        orderId: this.orderDetail.orderId,
        code: this.code,
        webType: this.webType,
        payWay: this.orderDetail.payWay
      }

      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration:0
      });
      let response = await pay(params)
      this.$toast.clear()
      if(+response.returnCode !== 10001) {
        //this.$toast(response.returnMsg)
        Dialog.alert({
          title: '温馨提示',
          message: response.returnMsg
        }).then(() => {
          window.location.href = this.requestUrl + 'course/signup/review?orderId='+ this.orderDetail.orderId
        });
        return false
      }
      // window.localStorage.setItem('payOrderId', this.orderDetail.orderId)
      this.$store.dispatch('setPayOrderId', this.orderDetail.orderId)

      if(+this.payWay === 1){//微信支付
        if(isWX) { //微信内置浏览器支付
          this.handleWeixinH5Pay(response.data)
          
        }else{
          // 浏览器使用微信支付
          let url = this.requestUrl +'course/signup/review?orderId='+ this.orderDetail.orderId + '&showCB=true'
          window.location.href = `${response.data.url}&redirect_url=${this.urlencode(url)}`
          //window.location.href = `${response.data.url}&redirect_url=${this.urlencode(window.location.href)}&showCB=true`
        
          
        }
      }else { //支付宝支付
        if(isWX) { //微信内置浏览器打开支付宝
          const div = document.createElement('divform');
          div.innerHTML = response.data;
          document.body.appendChild(div);
          let queryParam = '';
          Array.prototype.slice.call(document.querySelectorAll("input[type=hidden]")).forEach(function (ele) {
            queryParam += '&' + ele.name + "=" + encodeURIComponent(ele.value);
            });
          let gotoUrl = document.querySelector("form").getAttribute('action') + '&' +  queryParam;
          _AP.pay(gotoUrl);
          
        }else{
          const div = document.createElement('divform');
          div.innerHTML = response.data;
          document.body.appendChild(div);
          // document.forms[0].acceptCharset='GBK';//保持与支付宝默认编码格式一致，如果不一致将会出现：调试错误，请回到请求来源地，重新发起请求，错误代码 invalid-signature 错误原因: 验签出错，建议检查签名字符串或签名私钥与应用公钥是否匹配
          document.forms[0].acceptCharset='UTF-8';
          document.forms[0].submit();
        }
        
      }
    },

    async confirmWexinPay() {
      let orderId = this.orderId || this.$store.state.payOrderId
      //let courseGroupId = this.orderDetail.courseGroupId || this.$store.state.orderDetail.courseGroupId
      let response = await payIsComplete({
        orderId: orderId,
      })
      if(+response.returnCode !== 10001) {
        this.$toast("该订单未支付")
        window.location.href = this.requestUrl +'course/signup/review?orderId='+ orderId
      }else{
        window.location.href = this.requestUrl +'course/signup/review?orderId='+ orderId
      }
    },

    cancelWexinPay(){
      let orderId = this.orderId || this.$store.state.payOrderId
      //let courseGroupId = this.orderDetail.courseGroupId || this.$store.state.orderDetail.courseGroupId

      window.location.href = this.requestUrl +'course/signup/review?orderId='+ orderId

    },
    
    onBridgeReady(data) {
      const _this = this
      let orderId = this.$store.state.payOrderId || null
      //console.log(data)
      //alert('appId:' + data.appId  +'timeStamp:'+data.timeStamp +'nonceStr:' +data.nonceStr + 'package:' + data.prepayId +'signType:' + data.signType +'paySign:' +data.sign)
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
          appId:data.appId,     //公众号名称，由商户传入     
          timeStamp:data.timeStamp,         //时间戳，自1970年以来的秒数     
          nonceStr:data.nonceStr, //随机串     
          package:data.prepayId,     
          signType:data.signType,         //微信签名方式     
          paySign:data.sign //微信签名 
        },
        function(res) {
          if (res.err_msg == 'get_brand_wcpay_request:ok' ) {
          // 使用以上方式判断前端返回,微信团队郑重提示：
          //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            //alert("支付成功！")
            _this.showCB = true
          } else if (res.err_msg == 'get_brand_wcpay_request:cancel' ) {
            // alert("用户取消支付！")
            _this.showCB = true
          } else if (res.err_msg == 'get_brand_wcpay_request:fail' ) {
            // alert("支付失败！")
            _this.showCB = true
          }
      })
    },

    handleWeixinH5Pay(data) {
      if (typeof WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady)
          document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady)
        }
      } else {
        this.onBridgeReady(data)
      }
    },

  }
  
}
</script>
<style leng="less" scoped>
.review-box{
  background:#F3F3F3;
}
.reviewStatus{
  width:100%;
  background:#fff;
  text-align: center;
}
.reviewStatus img{
  display: block;
  width:1.2rem;
  height:1.2rem;
  margin:0.3rem auto;
}
.reviewStatus span{
  display: block;
  font-size: 14px;
  font-weight: bold;
  height:0.6rem;
  line-height: 0.6rem;
}
.reviewStatus p{
  font-size: 12px;
  line-height: 0.5rem;
  padding:0.1rem 0.3rem;
}
.reviewStatus a{
  display: block;
  font-weight: bold;
  text-decoration: underline;
  color: #2E8DF4;
  padding:0.2rem 0;
  font-size: 14px;
}
.payWay{
  /* width:94%; */
  padding:0 3%;
  margin:0.25rem 0;
  background: #fff;
}
.payWay .title{
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  line-height: 0.5rem;
  padding:0.2rem 0;
  border-bottom:1px #f2f2f5 solid;
}
.payWay .infoList{
  display: flex;
  font-size: 14px;
  color:#333;
  line-height: 0.8rem;
  text-align: left;
  align-items: center;
}
.payWay .infoList img{
  width:0.49rem;
  height: 0.4rem;
  margin-right: 0.2rem;
}
.payWay .infoList .way{
  flex-grow:2
}
.payWay .money{
  font-size: 16px;
  color:#333;
  line-height: 1rem;
  text-align: left;
  font-weight: bold;
}
.payWay .money .red{
  color:#FF3F47;
}
.paybox{
  /* width:94%; */
  padding:0 3%;
  background:#fff;
  margin-top:0.25rem;
  position: fixed;
  bottom:0;
  left: 0;
  right: 0;
  height:2rem;
}
.paybox p{
  font-size: 14px;
  color:#FF9540;
  text-align: center;
  height: 0.8rem;
  line-height: 0.8rem;
  /* padding:0.2rem 0; */
}
.paybox .payBtn{
  width:100%;
  height:0.78rem;
  line-height: 0.78rem;
  text-align: center;
  font-weight: bold;
  background: #FF3F47;
  color:#ffffff;
  border-radius: 10px;
  padding: 0;
  margin:0;
}
</style>